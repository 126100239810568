<template>
  <div class="field">
    <label class="label mb-0 pb-0">{{title}}</label>
    <div v-if="helptext" class="is-size-7 has-text-grey-light mt-0 pt-0 mb-1">
      {{ helptext }}
    </div>
      <div class="control">
        <div v-if="['audio', 'judged-audio', 'audio-words-recognition'].includes(type)">
        <!-- <div v-if="true"> -->
          <audio-input v-on:audioRecorded="processAudioRecorded($event)" />
        </div>
        <div v-else-if="['text'].includes(type)">
          <input
            class="input is-small"
            v-bind:class="{'is-medium': size === 'medium', 'is-large': size === 'large', 'is-danger': errorMessage, 'has-text-centered': centered}"
            :placeholder="getPlaceholder"
            :type="type"
            v-model="localValue"
          >
        </div>
        <div v-else-if="['textarea', 'text-ai', 'free-text', 'text-share', 'judged-text'].includes(type)">
          <textarea
          class="textarea"
          v-bind:class="{'is-medium': size === 'medium', 'is-large': size === 'large', 'is-danger': errorMessage}"
          :placeholder="getPlaceholder"
          v-model="localValue"
          rows="3"
          ></textarea>
        </div>
        <div v-else-if="type==='number'">
          <input
            class="input"
            v-bind:class="{'is-small has-short-input-max-width': !size, 'is-medium is-fullwidth has-text-centered': size === 'medium', 'is-large is-fullwidth has-text-centered': size === 'large', 'is-danger': errorMessage}"
            :placeholder="getPlaceholder"
            type="text"
            v-model="localValue"
            v-num
          >
        </div>
        <div v-else-if="type==='integer'">
          <input
            class="input"
            v-bind:class="{'is-small has-short-input-max-width': !size, 'is-medium is-fullwidth has-text-centered': size === 'medium', 'is-large is-fullwidth has-text-centered': size === 'large', 'is-danger': errorMessage}"
            :placeholder="getPlaceholder"
            :type="type"
            v-model="localValue"
            v-int
          >
        </div>
        <div v-else-if="type==='markdown'">
          <textarea
            class="textarea is-small"
            v-bind:class="{'is-medium': size === 'medium', 'is-large': size === 'large', 'is-danger': errorMessage}"
            v-model="localValue"
            :placeholder="getPlaceholder"
            ref="markdown"
          />
          <div
          class="modal"
          v-if="showMarkdownPreview"
          v-bind:class="{'is-active': showMarkdownPreview}"
          >
            <div @click="toggleMarkdownPreview()" class="modal-background pointer"></div>
            <div class="modal-card">
              <section class="modal-card-body">
                <div :key="markdown" v-markdown>{{ localValue }}</div>
              </section>
            </div>
            <button @click="toggleMarkdownPreview()" class="modal-close is-large" aria-label="close"></button>
          </div>
          <div class="buttons mt-1">
            <div @click="toggleMarkdownPreview()" class="button is-small is-light mr-1">
              show preview
            </div>
            <div @click="addStringToMarkdownArea('LINK')" class="button is-small is-light mr-1">
              add link
            </div>
            <div @click="addStringToMarkdownArea('IMAGE')" class="button is-small is-light mr-1">
              add image
            </div>
          </div>
        </div>
        <div v-else-if="type==='day'">
          <div
          class="select is-small"
          v-bind:class="{'is-medium': size === 'medium', 'is-large': size === 'large', 'is-danger': errorMessage}"
          >
            <select v-model="localValue">
              <option value="0">Sunday</option>
              <option value="1">Monday</option>
              <option value="2">Tuesday</option>
              <option value="3">Wednesday</option>
              <option value="4">Thursday</option>
              <option value="5">Friday</option>
              <option value="6">Saturday</option>
            </select>
          </div>
        </div>
        <div v-else-if="type==='time'">
          <input
            class="input is-small"
            v-bind:class="{'is-medium': size === 'medium', 'is-large': size === 'large', 'is-danger': errorMessage}"
            type="text"
            id="timepicker"
            autocomplete="off"
            v-model="localValue"
          />
        </div>
        <div v-else-if="type==='date'">
          <input
            class="input is-small"
            v-bind:class="{'is-medium': size === 'medium', 'is-large': size === 'large', 'is-danger': errorMessage}"
            type="text"
            id="datepicker"
            autocomplete="off"
            v-model="localValue"
          />
        </div>
        <div v-else-if="type==='boolean'">
          <button
            @click="toggleBoolean()"
            class="button is-small"
            v-bind:class="{ 'is-success': localValue, 'is-danger': !localValue, 'is-medium': size === 'medium', 'is-large': size === 'large' }"
          >
            {{ localValue ? "TRUE" : "FALSE"}}
          </button>
        </div>
        <div v-else-if="type==='selection'">
          <div class="buttons has-addons">
            <div v-for="(selection, index) in selections" :key="index">
              <button
                @click="selectSelection(index)"
                class="button is-small mr-1"
                v-bind:class="{ 'is-success': localValue === selection, 'is-light': localValue !== selection, 'is-medium': size === 'medium', 'is-large': size === 'large'}"
              >
                {{ selection }}
              </button>
            </div>
          </div>
        </div>
        <div v-else-if="type==='location'">
          <div :id="`map_${_uid}`" class="form-input-map-modifier short-map"></div>
          <div v-show="userLocation" class="container">
            <div class="is-size-7 has-text-centered mt-2 mb-1">Move <b>blue marker</b> to fine-tune your location. It may take up to 3 minutes for your device to update location.</div>
          </div>
          <a
          @click="getLocation()"
          class="button has-text-weight-semibold is-fullwidth is-small is-primary"
          :class="{ 'is-loading': loadingLocation }"
          >
            <i class="fas fa-map-marker mr-2"></i>
            Refresh location
          </a>
        </div>
        <div v-else-if="type==='qrbarcode'">
          <div v-if="openCamera" :key="cameraKey" class="container mb-1">
            <StreamBarcodeReader
              @decode="onQrbarcodeDecode"
            ></StreamBarcodeReader>
          </div>
          <div v-if="!openCamera && showManualInput" class="container">
            <input
              class="input is-small has-text-centered"
              v-bind:class="{'is-medium': size === 'medium', 'is-large': size === 'large'}"
              :placeholder="getPlaceholder"
              type="text"
              v-model="localValue"
            >
          </div>
          <div class="container">
            <div @click="toggleCamera()"
            class="button is-primary-colors has-text-weight-semibold is-fullwidth mt-2"
            v-bind:class="{'is-medium': size === 'medium', 'is-large': size === 'large'}"
            >
              <i class="fas fa-qrcode mr-2"></i>
              {{ openCamera ? t("Close camera") : t("Click here to start camera") }}
            </div>
          </div>
        </div>
        <div v-else-if="['image', 'image-share', 'judged-image', 'judged-image-ai', 'image-similarity', 'object-recognition'].includes(type)">
          <div :id="`preview-judged-image-${index}`" class="box has-text-centered mb-2" v-bind:class="{'is-hidden': !imageUrl }">
            <!-- <img :src="imageUrl"> -->
          </div>
          <div class="is-flex" style="width: 100%;">
            <button
              v-if="localValue"
              @click="() => { localValue = null; imageUrl = null}"
              class="button is-danger is-light has-text-weight-semibold mb-1 is-flex-grow-1 mr-1"
            >
              <i class="fas fa-trash mr-2"></i>
              Delete
            </button>
            <button
              @click="pickFile"
              class="button is-primary has-text-weight-semibold is-flex-grow-1"
              :class="{'is-loading': loading, 'is-light': imageUrl, 'is-primary': !imageUrl }"
            >
              <i v-if="!imageUrl" class="fas fa-camera mr-2"></i>
              <i v-if="imageUrl" class="fas fa-exchange-alt mr-2"></i>
              {{ !imageUrl ? `Select / take ${isAnother ? 'another ' : ''}photo` : 'Change' }}
            </button>
          </div>
          <input
          id="uploaded-image-judged-image"
          type="file"
          style="display: none"
          ref="fileInput"
          accept="image/*"
          @change="filePicked"
          >
        </div>
        <div v-else-if="['video', 'judged-video'].includes(type)">
          <div class="box has-text-centered mb-2" v-bind:class="{'is-hidden': !videoUrl }">
            <video width="320" height="240" controls>
              <source :src="videoUrl" type="video/mp4">
              <source :src="videoUrl" type="video/ogg">
              Your browser does not support the video tag.
            </video>
          </div>
          <div class="container has-text-centered">
            <div v-if="videoUrl && (this.video.size / 1024 / 1024) > 140" class="is-size-7 has-text-grey-light mb-1">
              Video size is too large at {{ (this.video.size / 1024 / 1024).toFixed(2) }} MB. Please re-upload.
            </div>
            <button
              @click="pickFileVideo"
              class="button is-primary has-text-weight-semibold is-fullwidth"
              :class="{'is-loading': loading, 'is-light': videoUrl, 'is-primary': !videoUrl }"
            >
              <i v-if="!videoUrl" class="fas fa-camera mr-2"></i>
              <i v-if="videoUrl" class="fas fa-exchange-alt mr-2"></i>
              {{ !videoUrl ? 'Select / take video' : 'Change video' }}
            </button>
          </div>
          <input
          type="file"
          style="display: none"
          ref="fileInputVideo"
          accept="video/*"
          @change="filePickedVideo"
          >
        </div>
        <div v-else-if="['file', 'judged-file'].includes(type)">
          <div class="container has-text-centered">
          <div v-if="documentUrl" class="file-preview mb-2">
            <i class="fas fa-file-alt fa-3x"></i>
            <p>{{ filename }}</p>
          </div>
            <button
              @click="pickFileDocument"
              class="button is-primary has-text-weight-semibold is-fullwidth"
              :class="{'is-loading': loading, 'is-light': documentUrl, 'is-primary': !documentUrl }"
            >
              <i v-if="!documentUrl" class="fas fa-file-upload mr-2"></i>
              <i v-if="documentUrl" class="fas fa-exchange-alt mr-2"></i>
              {{ !documentUrl ? 'Select file' : 'Change file' }}
            </button>
          </div>
          <input
          type="file"
          style="display: none"
          ref="fileInputDocument"
          accept=".pdf,.zip,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.jpg,.jpeg,.png,.gif,.mp4,.mov,.avi,.webp"
          @change="filePickedDocument"
          >
        </div>
      </div>
  </div>
</template>

<script>
import AudioInput from './AudioInput'
import flatpickr from "flatpickr"
import L from "leaflet"
import { StreamBarcodeReader  } from "vue-barcode-reader"
import loadImage from 'blueimp-load-image'

export default {
  name: 'FormInput',
  props: [
    'title', 'helptext', 'placeholder', 'type', 'value', 'selections',
    'size', 'errorMessage', 'capsOnly', 'centered', 'index', 'isAnother'
  ],
  components: {
    AudioInput,
    StreamBarcodeReader
  },
  data() {
    return {
      datetime: null,
      showMarkdownPreview: false,
      markdownAreaRef: null,
      map: null,
      cameraKey: 0,
      openCamera: false,
      showManualInput: true,
      image: null,
      imageUrl: null,
      filename: null,
      videoUrl: null,
      video: null,
      ext: null,
      loading: null,
      loadingLocation: false,
      document: null,
      documentUrl: null
    };
  },
  methods: {
    toggleBoolean(){
      this.localValue = !this.localValue
    },
    selectSelection(index){
      this.localValue = this.selections[index]
    },
    toggleMarkdownPreview(){
      this.showMarkdownPreview = !this.showMarkdownPreview
    },
    addStringToMarkdownArea(templateName){
      const templates = {
        'LINK': '[LINK_TITLE](LINK_URL)',
        'IMAGE': '![ALT_TEXT](IMAGE_URL "IMAGE_TEXT")'
      }
      const cursorPosition = this.markdownAreaRef.selectionStart
      this.localValue = [this.localValue.slice(0, cursorPosition), templates[templateName], this.localValue.slice(cursorPosition)].join('')
    },
    getLocation() {
      this.loadingLocation = true
      setTimeout(() => {
        this.loadingLocation = false
      }, 5000)
      this.$store.dispatch('getUserLocationAction', this.t)
    },
    initMap() {
      const location = [this.userLocation.lat, this.userLocation.lon]
      if(this.userLocation){
        let maxRadius = null
        if(this.$store.state.userAllowsLocationDetection) {
          maxRadius = 350
        }
        if(this.map){this.map.remove()}
        document.getElementById(`map_${this._uid}`).className = 'form-input-map-modifier'

        this.map = L.map(
          `map_${this._uid}`,
          { fullscreenControl: false }
        ).setView(location, 15)
        this.tileLayer = L.tileLayer(
          'https://{s}.api.tomtom.com/map/1/tile/basic/main/{z}/{x}/{y}.png?key=V2XqbAbuAC6W5SqSo3tQXBESfHSlqC5Q'
          // {
          //   attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>',
          // }
        )
        const marker = L.marker(location, {draggable: true}).addTo(this.map)
        if(maxRadius){
          L.circle(location, maxRadius).addTo(this.map)
        }
        this.tileLayer.addTo(this.map)
        marker.on('dragend', () => {
          if(
            maxRadius &&
            this.distanceBetweenTwoGeoCoordinatesInMeters(
            location[0], location[1], marker.getLatLng().lat, marker.getLatLng().lng) > maxRadius
          ){
            marker.setLatLng(location)
          } else {
            this.localValue = {
              lat: marker.getLatLng().lat,
              lon: marker.getLatLng().lng
            }
          }
        })
      }
    },
    distanceBetweenTwoGeoCoordinatesInMeters(lat1, lon1, lat2, lon2) {
      if ((lat1 == lat2) && (lon1 == lon2)) {
        return 0;
      }
      else {
        const radlat1 = Math.PI * lat1/180;
        const radlat2 = Math.PI * lat2/180;
        const theta = lon1-lon2;
        const radtheta = Math.PI * theta/180;
        let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
          dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180/Math.PI;
        dist = dist * 60 * 1.1515;
        dist = dist * 1.609344
        return dist*1000;
      }
    },
    toggleCamera(){
      this.value = null
      this.openCamera = !this.openCamera
      this.cameraKey += 1
      if (this.openCamera) {
        this.showManualInput = false
        setTimeout(() => {
          window.scrollTo(0, document.body.scrollHeight)
        }, 2000)
      } else {
        setTimeout(() => {
          this.showManualInput = true
        }, 3000)
      }
      this.$forceUpdate()
    },
    onQrbarcodeDecode(result){
      if(this.openCamera){
        this.localValue = result
        this.$emit('valueScanned', result)
      }
      this.openCamera = false
    },
    pickFile () {
      if(this.image){
        console.log('helloooo')
        const confirmed = confirm(this.t('Remove image and re-upload?', false))
        if (!confirmed) { return }
      }
      this.imageUrl = null
      this.filename = null
      this.image = null
      this.$refs.fileInput.click()
    },
    async filePicked (event) {
      this.loading = true
      const files = event.target.files
      const filename = files[0].name
      this.filename = filename
      this.ext = this.filename.slice(this.filename.lastIndexOf('.'))
      if (filename.lastIndexOf('.') <= 0) {
        return alert('Please add a valid file!')
      }
      this.image = files[0]
      // if (this.image.size / 1024 / 1024 > 20) {
      //   this.loading = false
      //   return alert(`File size (${(this.image.size / 1024 / 1024).toFixed()}MB) is too large, needs to be less than 20MB. Reduce photo quality by going to your camera settings.`)
      // }
      const previewElement = document.getElementById(`preview-judged-image-${this.index}`)
      while (previewElement.firstChild) {
        previewElement.removeChild(previewElement.lastChild);
      }
      loadImage(
        files[0],
        (img, data) => {
          if (data.imageHead && data.exif) {
            loadImage.writeExifData(data.imageHead, data, 'Orientation', 1)
            img.toBlob((blob) => {
              loadImage.replaceHead(blob, data.imageHead, (newBlob) => {
                const fileReader = new FileReader()
                fileReader.onload = () => {
                  const dataUrl = fileReader.result
                  this.imageUrl = dataUrl
                  this.processFilePicked()
                };
                fileReader.readAsDataURL(newBlob)
              })
            }, 'image/jpeg')
          }
          img.style.maxWidth = '100%'
          document.getElementById(`preview-judged-image-${this.index}`).appendChild(img)
        },
        { meta:true, orientation: true, canvas: true, maxWidth: '100%' }
      )
      const fileReader = new FileReader()
      fileReader.readAsDataURL(this.image)
      fileReader.addEventListener('load', () => {
        this.imageUrl = fileReader.result
        this.processFilePicked()
      })
      event.target.value = null
    },
    async processFilePicked () {
      this.localValue = {
        image: await fetch(this.imageUrl).then(r => r.blob()),
        imageUrl: this.imageUrl,
        ext: this.ext
      }
      this.loading = false
      window.scrollTo(0, 99999999999)
    },
    pickFileVideo () {
      if(this.video){
        const confirmed = confirm('Remove video and re-upload?')
        if (!confirmed) { return }
      }
      this.videoUrl = null
      this.filename = null
      this.video = null
      this.$refs.fileInputVideo.click()
    },
    async filePickedVideo (event) {
      this.loading = true
      const files = event.target.files
      this.video = files[0]
      const filename = files[0].name
      this.filename = filename
      if (filename.lastIndexOf('.') <= 0) {
        return alert('Please add a valid file!')
      }
      if (this.video.size / 1024 / 1024 > 140) {
        const videoSize = (this.video.size / 1024 / 1024).toFixed()
        this.videoUrl = null
        this.filename = null
        this.video = null
        this.loading = false
        return alert(`File size (${videoSize}MB) is too large, needs to be less than 140MB. Try shortening it, or using "Take Video" instead of uploading from your Gallery.`)
      }
      this.videoUrl = URL.createObjectURL(files[0])
      document.querySelector("video").src = this.videoUrl
      this.ext = this.filename.slice(this.filename.lastIndexOf('.'))
      const fileReader = new FileReader()
      fileReader.readAsDataURL(this.video)
      fileReader.addEventListener('load', () => {
        this.localValue = {
          video: this.video,
          videoUrl: fileReader.result,
          ext: this.ext
        }
        this.loading = false
        window.scrollTo(0, 99999999999)
      })
      event.target.value = null
    },
    pickFileDocument () {
      if(this.document){
        const confirmed = confirm('Remove document and re-upload?')
        if (!confirmed) { return }
      }
      this.documentUrl = null
      this.filename = null
      this.document = null
      this.$refs.fileInputDocument.click()
    },
    async filePickedDocument (event) {
      this.loading = true
      const files = event.target.files
      this.document = files[0]
      const filename = files[0].name
      this.filename = filename
      if (filename.lastIndexOf('.') <= 0) {
        return alert('Please add a valid file!')
      }
      this.documentUrl = URL.createObjectURL(files[0])
      this.ext = this.filename.slice(this.filename.lastIndexOf('.'))
      const fileReader = new FileReader()
      fileReader.readAsDataURL(this.document)
      fileReader.addEventListener('load', () => {
        this.localValue = {
          filename,
          document: this.document,
          documentUrl: fileReader.result,
          ext: this.ext
        }
        this.loading = false
        window.scrollTo(0, 99999999999)
      })
      event.target.value = null
    },
    processAudioRecorded (audioBlob) {
      console.log(audioBlob)
      if (!audioBlob) {
        this.localValue = null
      } else {
        this.localValue = {
          audio: audioBlob,
          ext: '.wav'
        }
      }
      this.loading = false
      window.scrollTo(0, 99999999999)
    }
  },
  computed: {
    localValue: {
      get () {
        return this.value
      },
      set (value) {
        if(value !== this.localValue){
          if(this.type === 'integer' || this.type === 'day'){
            if(!value){
              value = null
            } else {
              value = parseInt(value)
            }
          } else if (this.type === 'number'){
            // already fixed by using v-num
          } else if (this.type === 'date'){
            if(value === ''){
              value = null
            }
          } else if (this.type === 'markdown'){
            if(value === ''){
              value = null
            }
          } else if (this.type === 'text' || this.type === 'qrbarcode'){
            if(value === ''){
              value = null
            }
            if(this.capsOnly){
              value = value.toUpperCase()
            }
          } else if (this.type === 'image'){
            if(value === ''){
              value = null
            }
          }
          this.$emit('update', value)
        }
      }
    },
    getPlaceholder() {
      const placeholders = {
        text: 'Text',
        markdown: 'Markdown',
        number: 'Number',
        integer: 'Integer',
        qrbarcode: this.t('Type or use camera to scan')
      }
      if (this.placeholder == null) return placeholders[this.type]
      else return this.placeholder
    },
    userLocation(){
      return this.$store.state.userLocation
    }
  },
  mounted() {
    if(this.type === 'time'){
      flatpickr('#timepicker', {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
      })
    } else if(this.type === 'date'){
      flatpickr('#datepicker', {
        enableTime: false,
        noCalendar: false,
        dateFormat: "Y-m-d",
      })
    } else if(this.type === 'markdown'){
      this.markdownAreaRef = this.$refs.markdown
    } else if(this.type === 'location') {
      if(this.userLocation){
        this.initMap()
        this.localValue = {
          lat: this.userLocation.lat,
          lon: this.userLocation.lon
        }
        this.loadingLocation = false
      }
    }
  },
  watch: {
    userLocation(newUserLocation, oldUserLocation){
      if(this.type === 'location'
      && newUserLocation){
        this.initMap()
        this.localValue = {
          lat: newUserLocation.lat,
          lon: newUserLocation.lon
        }
        this.loadingLocation = false
      }
    }
  },
  destroyed() {
    if (this.map && this.map.remove) {
      this.map.off()
      this.map.remove()
      this.map = null
    }
  }
}
</script>

<style>
.uploaded-image-modifier {
  max-height: 250px;
}

.form-input-map-modifier {
  height: 250px;
}

.short-map {
  height: 1px !important;
}

.file-preview {
  margin-top: 10px;
  text-align: center;
}
</style>
